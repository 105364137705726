import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./LightboxLarge.scss";

function LightboxLarge({ setImageActive, largeImage, alt, caption, title }) {
  return (
    <>
      <button
        className="lightboxLarge"
        onClick={() => {
          setImageActive(false);
        }}
      >
        <div className="lightboxLarge__wrapper">
          <div
            className="lightboxLarge__exit"
            onClick={() => {
              setImageActive(false);
            }}
          >
            &#x2715;
          </div>
          <GatsbyImage
            image={largeImage}
            alt={alt}
            className="lightboxLarge__img"
            title={title}
          ></GatsbyImage>

          {caption && (
            <figcaption className="lightboxLarge__caption">
              {caption}
            </figcaption>
          )}
        </div>
      </button>
    </>
  );
}

export default LightboxLarge;
