import React from "react";
import { graphql } from "gatsby";
import useTitleContext from "../../context/useTitleContext";
import RichLink from "../../components/RichLink/RichLink";
import "./subMenus.scss";
import Lightbox from "../../components/Lightbox/Lightbox";
import Seo from "../../components/seo";

function SubMenu({ data, pageContext }) {
  const relations = pageContext.relations || null;
  const {
    markdownRemark: { frontmatter, html },
  } = data;
  const firstHeading = html.match(/<h..*?>([^<>]+)<\/h.>/)[1];
  const htmlBody = html.replace(/<h..*?>([^<>]+)<\/h.>/, "");
  const title = frontmatter.h1 || firstHeading;

  const hasRelations = relations && relations.length > 1;

  useTitleContext(title);
  return (
    <div className="subMenu container">
      <Seo title={title} description={htmlBody}></Seo>
      <div className="subMenu__main">
        <h2>{firstHeading}</h2>
        <Lightbox
          small={frontmatter.featuredImg}
          alt={frontmatter.alt}
          modifier="--submenu"
        ></Lightbox>
        <article
          className="subMenu__text"
          dangerouslySetInnerHTML={{ __html: htmlBody }}
        ></article>
        {hasRelations && <RepeatRichLink data={relations} title={title} />}
      </div>
    </div>
  );
}

function RepeatRichLink({ data, title }) {
  if (data[0] != undefined && data[0].h1 === title) {
    data.shift(); //remove the first element (this page itsself from the array)
  }
  return (
    <div className="subMenu__RichLink">
      <h2 id="more">Arbeiten:</h2>
      {data.map(entry => {
        const { relation, excerpt, featuredImg = null, h1 } = entry;
        const img = featuredImg?.childImageSharp;
        return (
          <RichLink
            key={relation}
            slug={relation}
            html={excerpt}
            image={img}
            h1={h1}
          ></RichLink>
        );
      })}
    </div>
  );
}

export const query = graphql`
  query Md($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        h1
        alt
        featuredImg {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      html
    }
  }
`;

export default SubMenu;
