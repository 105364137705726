import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LightboxLarge from "./LightboxLarge";
import "./Lightbox.scss";

/*
  IN:
  small = small image
  large = larger image
  caption = text
  alt = altText
  modifier = stylable classname modifier
*/

function Lightbox({
  small = null,
  large = null,
  caption = null,
  alt = null,
  modifier = "",
}) {
  const [imageActive, setImageActive] = useState(false);
  const smallImage = getImage(small || large);
  const largeImage = getImage(large);
  const addScrollToggle = () => {
    window.addEventListener(
      "scroll",
      () => {
        setImageActive(false);
      },
      { once: true }
    );
  };

  return (
    <>
      <button
        onClick={() => {
          setImageActive(true);
          addScrollToggle();
        }}
        className={`lightbox__preview lightbox__preview${modifier}`}
      >
        <GatsbyImage
          image={smallImage}
          alt={alt || caption || ""}
        ></GatsbyImage>
      </button>

      {imageActive && (
        <LightboxLarge
          setImageActive={setImageActive}
          largeImage={largeImage}
          alt={alt || caption || ""}
          caption={caption || alt}
          title={caption || alt || null}
        ></LightboxLarge>
      )}
    </>
  );
}

export default Lightbox;

/*
Docs:
  gatsby image options (in query)
    - placeholderClassName 
    - https://www.gatsbyjs.com/plugins/gatsby-image/#art-directing-multiple-images
    

*/
